import React from 'react';
import { BsTwitter, BsInstagram, BsGithub, BsLinkedin } from 'react-icons/bs';
import { FaFacebookF, FaDownload } from 'react-icons/fa';

const SocialMedia = () => (
  <div className='app__social'>
    <a href='https://github.com/AyoubMaimmadi' target='_blank' rel='noreferrer'>
      <div>
        <BsGithub />
      </div>
    </a>
    <a href='https://www.linkedin.com/in/ayoub-maimmadi-533587198/' target='_blank' rel='noreferrer'>
      <div>
        <BsLinkedin />
      </div>
    </a>
    <a href='https://www.instagram.com/ayoubmaimmadi/' target='_blank' rel='noreferrer'>
      <div>
        <BsInstagram />
      </div>
    </a>
    {/* <a href='https://drive.google.com/file/d/1C2zfttAZVWuZS46TsygdV0OrzM11SiPC/view?usp=sharing' target='_blank' rel='noreferrer'>
            <div title='Download CV'>
                <FaDownload />
            </div>
        </a> */}
    {/* <a href='https://www.facebook.com/AYOUB.MAIMMADI1/' target='_blank' rel='noreferrer'>
            <div>
                <FaFacebookF />
            </div>
        </a> */}
  </div>
);

export default SocialMedia;
